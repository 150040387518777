import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom"
import { LoginDoctor } from "./pages/LoginDoctor"
import "./App.css"
import { Results } from "./pages/Results"
import { Layout } from "./layout/Layout"
import { TermsAndConditions } from "./pages/TermsAndConditions"
import { PrivacyPolicy } from "./pages/PrivacyPolicy"
import { RegisterConfirmation } from "./pages/RegisterConfirmation"
import { DocumentDetails } from "./pages/DocumentDetails"
import { DocumentListWrapper } from "./components/documents-list/DocumentListWrapper"
import { ApiError } from "./components/ApiError"
import { Support } from "./pages/Support"
import { Suspense } from "react"
import { Loader } from "./components/Loader"
import { DEFAULT_LOCALE } from "./i18n"
import { AuthContextProvider } from "./contexts"
import { LoginLayout } from "./layout/Login"
import { LoginPatient } from "./pages/LoginPatient"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { DocumentCreate } from "./pages/DocumentCreate"
import { t } from "i18next"
import { NotificationProvider } from "./contexts/NotificationContext"
import { NewLayout } from "./layout/NewLayout"
import { Register } from "./pages/Register"
import { RegisterFb } from "./pages/RegisterFb"
// import { InitializeGoogleAnalytics } from "./analyticsGA"

export const routes = (language: string) => {
  return [
    {
      path: "/register",
      element: <NewLayout />,
      children: [
        {
          path: "/register",
          element: <Register />,
        },
        {
          path: "/register/fb",
          element: <RegisterFb />,
        },
      ],
    },
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Navigate to="/login/patient" />,
        },
        {
          path: "/login",
          element: <LoginLayout />,
          children: [
            {
              path: "/login/doctor",
              element: <LoginDoctor />,
            },
            {
              path: "/login/patient",
              element: <LoginPatient />,
            },
          ],
        },
        {
          path: "/results",
          breadcrumb: t("breadcrumb.main"),
          element: <Results />,
          children: [
            { index: true, element: <DocumentListWrapper />, errorElement: <ApiError /> },
            {
              path: ":documentId",
              element: <DocumentDetails />,
              errorElement: <ApiError />,
              breadcrumb: t("breadcrumb.results.item"),
            },
            {
              path: "add",
              element: <DocumentCreate />,
              errorElement: <ApiError />,
              breadcrumb: t("breadcrumb.results.add"),
            },
          ],
        },
        {
          path: "/terms",
          element: <TermsAndConditions />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/register-confirm",
          element: <RegisterConfirmation />,
        },
        {
          path: "/support",
          element: <Support />,
        },
      ],
    },
  ]
}

const router = createBrowserRouter(routes(DEFAULT_LOCALE), {
  future: {
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_skipActionErrorRevalidation: true,
  },
})
const queryClient = new QueryClient()

function App() {
  // useEffect(() => {
  //   InitializeGoogleAnalytics();
  // }, []);

  return (
    <Suspense fallback={<Loader />}>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <NotificationProvider>
            <RouterProvider
              router={router}
              future={{
                v7_startTransition: true,
              }}
            />
          </NotificationProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </Suspense>
  )
}

export default App
