import React, { useEffect } from "react"
import RegisterFacebook from "../components/register/RegisterFacebook"
import RegisterCard from "../components/register/RegisterCard"
import { useAuthContext } from "../contexts"
import { useNavigate } from "react-router-dom"

export const RegisterFb: React.FC = () => {
  const { signedInEntity } = useAuthContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (signedInEntity) {
      navigate("/results")
    }
  }, [signedInEntity])

  return (
    <RegisterCard>
      <RegisterFacebook></RegisterFacebook>
    </RegisterCard>
  )
}
