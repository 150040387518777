import { logEvent } from "firebase/analytics"
import { firebaseAnalytics } from "./firebaseAuth"

export const onAnalytics = async (eventName: string, eventParams: Record<string, any>) => {
  eventParams.type = "web"
  if (firebaseAnalytics) {
    logEvent(firebaseAnalytics, eventName, eventParams)
  } else {
    console.warn("Firebase Analytics is not supported in this environment.")
  }
}
