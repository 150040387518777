export enum DocumentStatus {
  Verified = "VERIFIED",
  WaitingVerification = "WAITING_VERIFICATION",
  ProcessFail = "PROCESS_FAIL",
  InProgress = "IN_PROGRESS",
  Created = "CREATED",
  Skipped = "SKIPPED",
}

export enum DocumentCategory {
  Lab = "LAB",
  Imaging = "IMAGING",
  Prescription = "PRESCRIPTION",
  Other = "OTHER",
}

export const DocumentVerified = [DocumentStatus.Verified, DocumentStatus.Skipped]

export const DocumentIsProcessing = [DocumentStatus.Created, DocumentStatus.InProgress]
