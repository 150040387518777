import { useEffect } from "react"
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp"

interface PasswordValidationProps {
  password: string
  onValidationChange: (isValid: boolean) => void
}

export const PasswordValidation = ({ password, onValidationChange }: PasswordValidationProps) => {
  const { t } = useTranslation()

  const requirements = [
    { label: t("register.password.length"), isValid: password.length >= 8 },
    { label: t("register.password.uppercase"), isValid: /[A-Z]/.test(password) },
    { label: t("register.password.digit"), isValid: /\d/.test(password) },
    { label: t("register.password.special"), isValid: /[!@#$%^&*(),.?":{}|<>]/.test(password) },
  ]

  const isPasswordValid = requirements.every(req => req.isValid)
  useEffect(() => {
    if (onValidationChange) {
      onValidationChange(isPasswordValid)
    }
  }, [isPasswordValid, onValidationChange])

  return (
    <List>
      <Typography variant="body1">{t("register.password.title")}</Typography>
      {requirements.map((req, index) => (
        <ListItem key={index} sx={{ m: 0, p: 0 }}>
          <ListItemIcon sx={{ minWidth: 30 }}>
            {req.isValid ? (
              <CheckCircleOutlineSharpIcon style={{ color: "blue" }} />
            ) : (
              <CheckCircleOutlineSharpIcon style={{ color: "gray" }} />
            )}
          </ListItemIcon>
          <ListItemText primary={req.label} />
        </ListItem>
      ))}
    </List>
  )
}
