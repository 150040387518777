import React from "react"
import { Box, Stack, Typography } from "@mui/material"
import { Outlet } from "react-router-dom"
import { useTranslation } from "react-i18next"

export const NewLayout: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Box>
      <Stack
        direction={{ xs: "column", md: "row" }}
        sx={{ height: "100vh", width: "100%" }}
        spacing={2}
      >
        <Stack
          sx={{ flex: 4, flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // color: { xs: "red", sm: "yellow", md: 'green', lg: 'blue' },
              padding: { xs: 1, sm: 0, md: 1, lg: 4 },
              width: "100%",
              height: "100%",
            }}
          >
            <Outlet />
          </Stack>
        </Stack>
        <Stack
          sx={{
            flex: 5,
            backgroundImage: "url(/image.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            height: "100vh",
            minHeight: "300px",
          }}
        >
          <Typography
            fontFamily="Poppins"
            variant="h4"
            sx={{
              position: "absolute",
              bottom: { xs: "5%", sm: "20%" },
              left: "50%",
              transform: "translateX(-50%)",
              color: "white",
              padding: 1,
              borderRadius: 1,
              lineHeight: "60px",
              textAlign: "left",
              width: "100%",
              maxWidth: "90%",
              boxSizing: "border-box",
              fontSize: { xs: "1.5rem", sm: "2.125rem" },
            }}
          >
            {t("login.title")}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
