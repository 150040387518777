import { useMutation, useQueryClient } from "@tanstack/react-query"
import { createEmailUser } from "../api/auth"
import { FirebaseError } from "firebase/app"
import { onAnalytics } from "../analytics"

export const useCreateEmailFirebase = () => {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, isSuccess, error, reset } = useMutation<
    void,
    FirebaseError,
    { email: string; password: string }
  >({
    mutationKey: [createEmailUser.name],
    mutationFn: async ({ email, password }) => {
      await createEmailUser(email, password)
      await onAnalytics("register_web", { email, type: "email" })
      queryClient.removeQueries()
    },
  })

  return {
    createUser: mutateAsync,
    isPending,
    isError,
    isSuccess,
    error: error,
    resetError: reset,
  }
}
