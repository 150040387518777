import React from "react"
import {
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { usePostCreateDocument } from "../../hooks/usePostCreateDocument"
import { useTranslation } from "react-i18next"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"

import { MenuItem } from "@mui/material"
import { DocumentCategory } from "../../types/document"
import { DocumentCategoryIcon } from "../DocumentCategoryIcon"
import dayjs from "dayjs"
import { FileWithPath } from "react-dropzone/."
import { useNavigate } from "react-router-dom"
import ImagePreview from "./ImagePreview"
import { useNotification } from "../../contexts/NotificationContext"

export type CreateDocumentInputs = {
  title: string
  file: File
  category: string
  samplingDate: string
}

const ResultsForm: React.FC<{ fileZone: FileWithPath[] }> = ({ fileZone }) => {
  const { t } = useTranslation()
  const { showNotification } = useNotification()
  const { handleSubmit, register, control } = useForm<CreateDocumentInputs>()
  const { mutate, isPending, isError } = usePostCreateDocument()
  const navigate = useNavigate()
  const handleFormSubmit: SubmitHandler<CreateDocumentInputs> = (data, event) => {
    if (fileZone && fileZone[0]) {
      mutate(
        {
          file: fileZone[0],
          title: data?.title || "",
          category: data.category,
          samplingDate: data.samplingDate,
        },
        {
          onSuccess: async data => {
            navigate("/results")
            showNotification({
              title: t("results.upload.success.title"),
              message: t("results.upload.success.message"),
              severity: "success",
            })
          },
        }
      )
    }
  }
  return (
    <Box sx={{ flex: 1 }} className="container">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing={2}>
          <TextField
            id="title"
            label={t("results.title")}
            {...register("title")}
            fullWidth
            defaultValue={""}
            helperText={t("results.titleInfo")}
          />
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <Controller
                  name="samplingDate"
                  control={control}
                  defaultValue={dayjs().toString()}
                  render={({ field }) => (
                    <DatePicker
                      label={t("results.date")}
                      slotProps={{
                        textField: {
                          required: true,
                          helperText: t("results.dateInfo"),
                        },
                      }}
                      format="DD/MM/YYYY"
                      disableFuture
                      onChange={date => field.onChange(date?.toString())}
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>

            <FormControl fullWidth>
              <InputLabel>{t("results.category")}</InputLabel>
              <Select
                {...register("category")}
                defaultValue={DocumentCategory.Lab}
                label="Category"
                fullWidth
              >
                {Object.values(DocumentCategory).map(category => (
                  <MenuItem
                    key={category}
                    value={category}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <DocumentCategoryIcon category={category} sx={{ marginRight: 2 }} />
                    {t(`documentCategory.${category.toLowerCase()}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              type="submit"
              disabled={isPending}
            >
              {isPending ? <CircularProgress size={24} /> : t("results.submitButton")}
            </Button>
          </Box>
        </Stack>
      </form>

      <ImagePreview file={fileZone[0]} />
      {isError && (
        <Typography color="error" align="center">
          {t(`file.200`)}
        </Typography>
      )}
    </Box>
  )
}

export default ResultsForm
