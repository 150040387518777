import { Box, Typography, Container } from "@mui/material"
import logoPl from "../../assets/images/teczka-pacjenta-logo.svg"
import logoEn from "../../assets/images/logo-en.svg"
import logoPon from "../../assets/images/logo-br.svg"
import { useTranslation } from "react-i18next"
import Link from "@mui/material/Link"
import { ReactNode } from "react"

const logoMap: Record<string, string> = {
  pl: logoPl,
  en: logoEn,
  br: logoPon,
}

interface RegisterCardProps {
  children: ReactNode
}

export default function RegisterCard({ children }: RegisterCardProps) {
  const { t, i18n } = useTranslation()
  const logo = logoMap[i18n.language] || logoEn
  return (
    <>
      <Container>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // gap:0
            // backgroundColor: "#f5f5f5",
          }}
        >
          <Box
            component="img"
            src={logo}
            alt={t("logo.alt")}
            sx={{ maxWidth: "100%", height: "auto", mt: 2, mb: 2 }}
          />

          <Box sx={{ width: "100%", textAlign: "left" }}>
            <Typography variant="h2" gutterBottom style={{ fontWeight: "bold" }}>
              {t("register.title")}
            </Typography>
            <Typography
              mt={2}
              mb={2}
              variant="subtitle1"
              sx={{ color: "#475467", textTransform: "none", mb: 2 }}
            >
              {t("register.subtitle")}
            </Typography>
          </Box>
          {children}
          <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
            <Typography sx={{ textAlign: "center" }}>
              {t("register.haveAccount")}{" "}
              <Link
                href="/login/patient"
                variant="body2"
                underline="none"
                sx={{ alignSelf: "center", fontWeight: "bold" }}
              >
                {t("register.login")}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  )
}
