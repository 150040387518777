import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"

export const DEFAULT_LOCALE = "pl"
const supportedLngs = ["pl", "en", "br"]

const domainLocaleMap: Record<string, string> = {
  localhost: "pl",
  "teczkapacjenta.pl": "pl",
  "app.dev.teczkapacjenta.pl": "pl",
  "myhealthfolder.online": "en",
  "registromedico.online": "br",
}

const titleMap: Record<string, string> = {
  pl: "Teczka Pacjenta",
  en: "Health Folder",
  br: "Registro Médico",
}

const getLocaleFromParams = () => {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search)
    const langParam = urlParams.get("lang")
    if (langParam && supportedLngs.includes(langParam)) {
      return langParam
    }
  }
}

const domainDetector = () => {
  let locale = DEFAULT_LOCALE
  if (typeof window !== "undefined") {
    locale = domainLocaleMap[window.location.hostname] || DEFAULT_LOCALE
  }
  return locale
}

const handleLangByHostname = () => {
  const locale = getLocaleFromParams() || domainDetector()

  document.documentElement.setAttribute("lang", locale)
  document.title = titleMap[locale] || "Health Folder"
  return locale
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: handleLangByHostname(),
    fallbackLng: DEFAULT_LOCALE,
    supportedLngs,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
