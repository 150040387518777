import { useSearchParams } from "react-router-dom"
import { useAuthContext } from "../contexts"
import { useQuery } from "@tanstack/react-query"
import { getDoctorDocumentList, getPatientDocumentList } from "../api/document"
import { onAnalytics } from "../analytics"

export const useGetDocuments = () => {
  const [searchParams] = useSearchParams()
  const searchTerms = searchParams.getAll("terms")
  const cat = searchParams.getAll("cat")

  const { signedInEntity } = useAuthContext()
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [getPatientDocumentList.name, searchTerms.join(), cat.join()],
    queryFn: async () => {
      const fn = signedInEntity?.type === "doctor" ? getDoctorDocumentList : getPatientDocumentList
      await onAnalytics("documents_view_web", {
        user: signedInEntity?.type,
      })
      return await fn({
        token: signedInEntity?.token ?? "",
        query: searchTerms,
        cat,
      })
    },
    enabled: !!signedInEntity,
  })

  return {
    documents: data?.documents,
    isLoading,
    isError,
    refetch,
  }
}
