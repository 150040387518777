import { Button, Box, InputLabel, Stack, CircularProgress } from "@mui/material"
import facebookIcon from "../../assets/images/facebook-icon.svg"
import { useNavigate } from "react-router-dom"
import CountrySelect from "../CountrySelect"
import { useSignInPatientWithFacebook } from "../../hooks/useSignInPatientWithFacebook"
import { useAuthContext } from "../../contexts"
import { useCallback, useEffect, useState } from "react"
import { CountryType } from "../../utils/countries"
import { UnauthorizedUserModal } from "./UnauthorizedUserModal"
import { usePostCreateUser } from "../../hooks/usePostCreateUser"
import { useTranslation } from "react-i18next"
import { useGetMe } from "../../hooks/useGetMe"

export default function RegisterFacebook() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isPendingLogin, setIsPendingLogin] = useState(false)
  const { signIn: signInWithFacebook } = useSignInPatientWithFacebook()
  const { getMe } = useGetMe({ retry: 5 })
  const { isUnauthorizedUserInDBError, signOut, signedInNouAuthorizedEntity } = useAuthContext()
  const { mutate } = usePostCreateUser()
  const [selectedCountry, setSelectedCountry] = useState("")
  const [showModal, setShowModal] = useState(false)
  const handleCountryChange = useCallback((country: CountryType) => {
    setSelectedCountry(country.code)
  }, [])

  const handleCloseModal = async () => {
    setShowModal(false)
    await signOut()
    navigate("/register", { replace: true })
  }
  const handleConfirmModal = (data: any) => {
    setShowModal(false)
    mutate(
      {
        ...data,
        country: selectedCountry,
        firstName: signedInNouAuthorizedEntity?.displayName[0],
        lastName: signedInNouAuthorizedEntity?.displayName[1],
      },
      {
        onSuccess: async () => {
          setIsPendingLogin(true)
          await getMe()
          window.location.reload()
          navigate("/results")
        },
        onError: async error => {
          setIsPendingLogin(false)
          window.location.reload()
        },
      }
    )
  }

  const handleSignInWithFacebookClick = () => {
    signInWithFacebook()
  }
  useEffect(() => {
    if (isUnauthorizedUserInDBError && selectedCountry) {
      setShowModal(true)
    }
  }, [isUnauthorizedUserInDBError, selectedCountry])
  return (
    <>
      <Box sx={{ width: "100%", textAlign: "left", minWidth: "50%" }}>
        <Box component="form" autoComplete="off">
          <Stack spacing={1}>
            <InputLabel htmlFor="email">{t("register.form.label.country")}</InputLabel>
            <CountrySelect key="debug-key" onCountryChange={handleCountryChange}></CountrySelect>
          </Stack>
        </Box>
        <Button
          variant="outlined"
          fullWidth
          startIcon={<img src={facebookIcon} alt="Facebook Icon" />}
          onClick={handleSignInWithFacebookClick}
          color="primary"
          size="medium"
          disabled={!selectedCountry || isPendingLogin}
          sx={{
            mt: 4,
            borderRadius: "12px",
            borderColor: "#D0D5DD",
            color: "#101828",
            fontWeight: "600",
          }}
        >
          {isPendingLogin && <CircularProgress size={24} sx={{ marginRight: "6px" }} />}
          {t("register.continueFacebook")}
        </Button>
      </Box>
      <UnauthorizedUserModal
        open={showModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmModal}
      />
    </>
  )
}
