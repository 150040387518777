import {
  TableCell,
  Grid,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import { format } from "date-fns/fp"
import React, { useState } from "react"
import { ExaminationType } from "../utils"
import { DocumentCategoryIcon } from "./DocumentCategoryIcon"
import { DocumentPreviewButton } from "./DocumentPreviewButton"
import { MeasurementList } from "./MeasurementList"
import {
  DocumentCategory,
  DocumentIsProcessing,
  DocumentStatus,
  DocumentVerified,
} from "../types/document"
import { useNavigate } from "react-router-dom"
import { Measurement } from "../types/measurement"
import { useTranslation } from "react-i18next"
import DeleteIcon from "@mui/icons-material/Delete"
import { useDeleteDocument } from "../hooks/useDeleteDocument"
import { useNotification } from "../contexts/NotificationContext"

export interface LabDocumentItemProps {
  documentId: string
  samplingDate: string
  status: DocumentStatus
  category: DocumentCategory
  title: string
  examinations: Array<{
    type: ExaminationType
    measurements: Measurement[]
  }>
}

export const LabDocumentItem: React.FC<LabDocumentItemProps> = ({
  documentId,
  category,
  title,
  samplingDate,
  status,
  examinations,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { mutate } = useDeleteDocument()
  const { showNotification } = useNotification()
  const [open, setOpen] = useState(false)

  const showDetails = (documentId: string) => {
    return navigate(`${documentId}`)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirmDelete = () => {
    mutate(documentId, {
      onSuccess: async () => {
        showNotification({
          message: t("results.remove.success.message"),
          title: t("results.remove.success.title"),
        })
        handleClose()
      },
      onError: () => {
        showNotification({
          message: t("results.remove.error.message"),
          title: t("results.remove.error.title"),
        })
      },
    })
  }

  const isProcessed = DocumentVerified.includes(status)
  const isProcessing = DocumentIsProcessing.includes(status)
  const isError = status === DocumentStatus.ProcessFail

  return (
    <>
      <TableCell>
        <Grid container columnSpacing={{ md: 1, sm: 2 }}>
          <Grid item xxs={12} xs={5} sm={3.5} md={2.5} display="flex">
            <DocumentCategoryIcon category={category} sx={{ mr: 2, width: 28, height: 28 }} />
            <Typography>{format("dd.MM.yyyy", new Date(samplingDate))}</Typography>
          </Grid>
          <Grid item xxs={12} xs={7} sm={8.5} md={4.5}>
            <Typography noWrap>
              <Typography fontWeight={700} component="span">
                {t(`documentCategory.${category.toLowerCase()}`)}
              </Typography>
              <Typography component="span" fontStyle={title.length > 0 ? "normal" : "italic"}>
                : {title || t("documentList.noTitle")}
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={5} sm={12}>
            <Grid container justifyContent="flex-end" columnSpacing={1} rowSpacing={0.5}>
              <Grid item>
                <IconButton aria-label="delete" size="small" onClick={handleClickOpen}>
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Grid>
              {isProcessed ? (
                <Grid item>
                  <Button
                    onClick={() => showDetails(documentId)}
                    color="primary"
                    variant="outlined"
                    size="small"
                  >
                    {t("documentList.buttonText")}
                  </Button>
                </Grid>
              ) : null}

              <Grid item>
                {isProcessing ? (
                  <Button
                    color="warning"
                    variant="contained"
                    size="small"
                    sx={{
                      cursor: "default",
                      borderRadius: 25,
                      paddingX: 1.5,
                    }}
                  >
                    {t("documentList.processingButtonText")}
                  </Button>
                ) : null}
                {isError ? (
                  <Button
                    color="error"
                    variant="contained"
                    size="small"
                    sx={{
                      cursor: "default",
                      borderRadius: 25,
                      paddingX: 1.5,
                    }}
                  >
                    {t("documentList.errorButtonText")}
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <DocumentPreviewButton
                  documentId={documentId}
                  size="small"
                  buttonText={t("documentList.previewButtonText")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} sx={{ mt: 1 }}>
            {examinations.map((examination, index) => (
              <Grid
                key={`${examination}-${index}`}
                container
                sx={{
                  borderTop: "1px solid #D0D4D7",
                  paddingTop: 1,
                  paddingBottom: { md: 2, sm: 1 },
                }}
                columnSpacing={{ sm: 1, md: 1 }}
                alignItems="flex-start"
              >
                <Grid item xs={12} sm={12} md={3}>
                  <Typography>{t("examinationType." + examination.type)}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} mb={2}>
                  <MeasurementList measurements={examination.measurements.map(m => m.name)} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </TableCell>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("documentList.confirmDeleteTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("documentList.confirmDeleteMessage")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("documentList.cancelButtonText")}
          </Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            {t("documentList.confirmButtonText")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
