import React, { createContext, useContext, useState, ReactNode } from "react"
import { Snackbar, Alert, AlertTitle, AlertColor, Stack, Typography } from "@mui/material"

interface AlertInputs {
  title: string
  severity?: AlertColor
  message: string
}

interface NotificationContextProps {
  showNotification: (notification: AlertInputs) => void
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined)

export const useNotification = () => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error("useNotification must be used within a NotificationProvider")
  }
  return context
}

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState("")
  const [title, setTitle] = useState("")
  const [severity, setSeverity] = useState<AlertColor>("info")

  const showNotification = ({ title, message, severity = "success" }: AlertInputs) => {
    setMessage(message)
    setSeverity(severity)
    setTitle(title)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Stack spacing={2} sx={{ width: "80%", padding: "0px" }}>
          <Alert
            onClose={handleClose}
            variant="outlined"
            severity={severity}
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              textTransform: "none",
              padding: "10px",
              borderRadius: "5px",
              border: "1px black",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            }}
          >
            {title && (
              <AlertTitle sx={{ color: "black", textTransform: "none" }}>{title}</AlertTitle>
            )}
            <Typography
              sx={{
                color: "black",
                fontWeight: "normal",
                textTransform: "none",
                lineHeight: "1.2",
              }}
              component="span"
            >
              {message}
            </Typography>
          </Alert>
        </Stack>
      </Snackbar>
    </NotificationContext.Provider>
  )
}
