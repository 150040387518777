import {
  Button,
  TextField,
  Box,
  Divider,
  Grid,
  Typography,
  CircularProgress,
  InputLabel,
  Stack,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import facebookIcon from "../../assets/images/facebook-icon.svg"
import { useNavigate } from "react-router-dom"
import { CountryType } from "../../utils/countries"
import { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { useCreateEmailFirebase } from "../../hooks/useCreateEmailFirebase"
import { UnauthorizedUserModal } from "./UnauthorizedUserModal"
import { usePostCreateUser } from "../../hooks/usePostCreateUser"
import { PasswordValidation } from "./PasswordValidation"
import { useSignInPatient } from "../../hooks/useSignInPatient"
import NewCountrySelect from "../CountrySelect"
import { useGetMe } from "../../hooks/useGetMe"

type FormInputs = {
  firstName: string
  lastName: string
  email: string
  country: string
  password: string
  confirmPassword: string
}

export default function RegisterForm() {
  const initState = {
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    password: "",
    confirmPassword: "",
  }
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isPendingLogin, setIsPendingLogin] = useState(false)
  const [formData, setFormData] = useState<FormInputs>({ ...initState })
  const [showPassword, setShowPassword] = useState(false)
  const { createUser, error: createError, isPending, resetError } = useCreateEmailFirebase()
  const { signIn, isPending: isPendingFirebase, isSuccess } = useSignInPatient({ retry: 3 })
  const { getMe } = useGetMe({ retry: 5 })
  const [showModal, setShowModal] = useState(false)
  const [passwordError, setPasswordError] = useState("")
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    register,
    watch,
  } = useForm<FormInputs>({
    defaultValues: formData,
    mode: "onChange",
  })

  const handleSignInWithFacebookClick = () => navigate("/register/fb")
  const handleCloseModal = () => setShowModal(false)

  const { mutateAsync: createUserInDb } = usePostCreateUser()
  const handleConfirmModal = async (data: any) => {
    if (!formData) return

    try {
      setIsPendingLogin(true)
      await createUser({ email: formData.email, password: formData.password })
      await signIn({ email: formData.email, password: formData.password })
      await createUserInDb({
        ...data,
        firstName: formData.firstName,
        lastName: formData.lastName,
        country: formData.country,
      })
      await getMe()
      setIsPendingLogin(false)
      window.location.reload()
      navigate("/results")
    } catch (error: any) {
      setIsPendingLogin(false)
      setShowPassword(false)
      if (error) {
        setPasswordError(error?.code)
      }
      resetError()
      setShowModal(false)
    }
  }

  const onSubmit = (data: FormInputs) => {
    setFormData(data)
    setShowModal(true)
  }

  const password = watch("password")
  const showRegisterForm = !showPassword || passwordError
  return (
    <>
      <Box sx={{ width: "100%", textAlign: "left" }}>
        {showRegisterForm ? (
          <>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<img src={facebookIcon} alt="Facebook Icon" />}
              onClick={handleSignInWithFacebookClick}
              color="primary"
              size="medium"
              sx={{
                borderRadius: "12px",
                borderColor: "#D0D5DD",
                // color: "#101828",
                fontWeight: "600",
              }}
            >
              {t("register.buttonFacebookRegister")}
            </Button>

            <Divider sx={{ m: 2 }}>{t("login.patient.divider")}</Divider>

            <Box
              component="form"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
              sx={{ "& .MuiTextField-root": { m: 0 } }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ width: "100%" }}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email">{t("register.form.label.name")}</InputLabel>
                    <TextField
                      fullWidth
                      placeholder={t("register.form.firstName")}
                      id="firstName"
                      {...register("firstName")}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ width: "100%" }}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email" sx={{ color: "#101828" }}>
                      {t("register.form.label.lastName")}
                    </InputLabel>
                    <TextField
                      fullWidth
                      placeholder={t("register.form.lastName")}
                      id="firstName"
                      {...register("lastName")}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ width: "100%" }}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email" sx={{ color: "#101828" }}>
                      {t("register.form.email")}
                    </InputLabel>
                    <TextField
                      {...register("email", {
                        required: t("register.form.emailRequired"),
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: t("register.form.emailInvalid"),
                        },
                      })}
                      placeholder={t("register.form.email")}
                      required
                      fullWidth
                      type="email"
                      variant="outlined"
                      margin="normal"
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ width: "100%" }}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email">{t("register.form.label.country")}</InputLabel>
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <NewCountrySelect
                          onCountryChange={(country: CountryType) => field.onChange(country.code)}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} mt={1} sx={{ width: "100%" }}>
                  {passwordError && (
                    <Typography color="red" align="center">
                      {t(`login.patient.error.${passwordError}`)}
                    </Typography>
                  )}
                  <Button
                    onClick={() => {
                      setShowPassword(true)
                      setPasswordError("")
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "12px" }}
                    disabled={!isValid}
                  >
                    {t("register.go")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ "& .MuiTextField-root": { m: 0 } }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ width: "100%" }}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password" sx={{ color: "#101828" }}>
                    {t("register.form.label.password")}
                  </InputLabel>
                  <TextField
                    {...register("password", {
                      required: t("register.form.passwordRequired"),
                      minLength: {
                        value: 6,
                        message: t("register.form.passwordMinLength"),
                      },
                    })}
                    placeholder="••••••••••"
                    required
                    fullWidth
                    type="password"
                    error={!!errors.password}
                  />
                </Stack>
                <PasswordValidation password={password} onValidationChange={setIsPasswordValid} />
              </Grid>
              <Grid item xs={12} sx={{ width: "100%" }}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="confirmPassword" sx={{ color: "#101828" }}>
                    {t("register.form.confirmPassword")}
                  </InputLabel>
                  <TextField
                    {...register("confirmPassword", {
                      validate: value => value === password || t("register.password.match"),
                    })}
                    required
                    fullWidth
                    placeholder="••••••••••"
                    type="password"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword ? errors.confirmPassword.message : ""}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sx={{ width: "100%" }}>
                {createError && (
                  <Typography color="red" align="center">
                    {t(`login.patient.error.${createError.code}`)}
                  </Typography>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ borderRadius: "12px" }}
                  disabled={!isPasswordValid || !isValid}
                >
                  {isPending || isPendingFirebase ? (
                    <CircularProgress size={24} />
                  ) : (
                    t("register.submitButton")
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <UnauthorizedUserModal
        isDisabled={isPendingLogin}
        isPending={isPending || isPendingFirebase || isSuccess || isPendingLogin}
        open={showModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmModal}
      />
    </>
  )
}
