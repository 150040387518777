import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import SearchDocumentItem from "./SearchDocumentItem"
import { DocumentCategory, DocumentStatus } from "../types/document"
import { ExaminationType } from "../utils"
import { Measurement } from "../types/measurement"

export interface SearchDocumentListProps {
  title?: string
  documents: Array<{
    documentId: string
    samplingDate: string
    status: DocumentStatus
    category: DocumentCategory
    title: string
    examinations: Array<{
      type: ExaminationType
      measurements: Measurement[]
    }>
  }>
}

export const SearchDocumentList: React.FC<SearchDocumentListProps> = ({ documents, title }) => {
  const { t } = useTranslation()

  return (
    <>
      {documents.length === 0 ? (
        <Box>
          <Typography>
            <FontAwesomeIcon icon={faCircleInfo} color="#597FFF" />{" "}
            {t("documentList.noDocumentsFound")}
          </Typography>
        </Box>
      ) : (
        <>
          {title && (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <Typography variant="h6">{title}</Typography>
            </Box>
          )}
          <TableContainer>
            <Table sx={{ borderSpacing: "0 16px", borderCollapse: "separate" }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Grid container columnSpacing={{ xs: 0, sm: 2, md: 2 }}>
                      <Grid item md={2.5} sm={3} xxs={12}>
                        <Box sx={{ paddingLeft: 6 }}>{t("documentList.date")}</Box>
                      </Grid>
                      <Grid item md={8} sm={9} xxs={12}>
                        <Grid container>
                          <Grid item xxs>
                            {t("documentList.parameters")}
                          </Grid>
                          <Grid item xxs>
                            {t("documentList.results")}
                          </Grid>
                          <Grid item xxs>
                            {t("documentList.referenceRange")}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.map(document => (
                  <SearchDocumentItem key={document.documentId} document={document} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}
