import { useAuthContext } from "../contexts"
import { useMutation } from "@tanstack/react-query"
import { postCreateUser, UserFormType } from "../api/user"
import { onAnalytics } from "../analytics"

export const usePostCreateUser = () => {
  const { signedInNouAuthorizedEntity } = useAuthContext()
  const { mutate, data, isError, isPending, error, mutateAsync } = useMutation({
    mutationKey: ["postCreateUser"],
    mutationFn: async (data: UserFormType) => {
      const results = await postCreateUser({
        data,
        token: signedInNouAuthorizedEntity?.token ?? "",
      })
      await onAnalytics("register_db_web", {})
      return results
    },
  })
  return { mutate, mutateAsync, data, isPending, isError, error }
}
