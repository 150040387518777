import { useAuthContext } from "../contexts"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { postCreateDocument } from "../api/document"
import { CreateDocumentInputs } from "../components/results/ResultsForm"
import { getPatientDocumentList } from "../api/document"
import { useSearchParams } from "react-router-dom"
import { onAnalytics } from "../analytics"

export const usePostCreateDocument = () => {
  const { signedInEntity } = useAuthContext()
  const [searchParams] = useSearchParams()
  const searchTerms = searchParams.getAll("terms")
  const cat = searchParams.getAll("cat")
  const queryClient = useQueryClient()
  const { mutate, data, isError, isPending, error } = useMutation({
    mutationKey: ["postCreateDocument"],
    mutationFn: async (formData: CreateDocumentInputs) => {
      const data = await postCreateDocument({ data: formData, token: signedInEntity?.token ?? "" })
      await onAnalytics("document_upload_web", { uid: signedInEntity?.uid })
      return data
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [getPatientDocumentList.name, searchTerms.join(), cat.join()],
      })
    },
  })
  return { mutate, data, isPending, isError, error }
}
