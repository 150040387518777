import { apiURL, createDefaultHeaders, createDefaultLambdaHeaders, lambdaApiURL } from "."

type UserDto = {
  firstName: string
  lastName: string
  country: string
}

export interface UserFormType {
  firstName: string
  lastName: string
  country: string
}

export class Unauthorized extends Error {
  // eslint-disable-next-line
  constructor(message: string) {
    super(message)
  }
}

export const getMe = async ({ token }: { token: string }): Promise<UserDto> => {
  const url = `${apiURL}/users/me`

  const response = await fetch(url, { headers: createDefaultHeaders(token) })
  if (response.status === 401) {
    throw new Unauthorized("User not found")
  }
  if (!response.ok) {
    throw new Error("Request failed")
  }

  return await response.json()
}

export const postCreateUserOld = async ({ data, token }: { data: UserFormType; token: string }) => {
  const url = `${apiURL}/users`
  const response = await fetch(url, {
    method: "POST",
    headers: createDefaultHeaders(token),
    body: JSON.stringify(data),
  })
  if (!response.ok) {
    throw new Error("Request failed")
  }
  return { success: true }
}

export const postCreateUser = async ({ data, token }: { data: UserFormType; token: string }) => {
  const url = `${lambdaApiURL}`
  const response = await fetch(url, {
    method: "POST",
    headers: createDefaultLambdaHeaders(token),
    body: JSON.stringify(data),
  })
  if (!response.ok) {
    throw new Error("Request failed")
  }
  return { success: true }
}
