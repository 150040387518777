import { useAuthContext } from "../contexts"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { deleteDocument } from "../api/document"
import { useSearchParams } from "react-router-dom"
import { getPatientDocumentList } from "../api/document"
import { onAnalytics } from "../analytics"

export const useDeleteDocument = () => {
  const { signedInEntity } = useAuthContext()
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const searchTerms = searchParams.getAll("terms")
  const cat = searchParams.getAll("cat")

  const { mutate, data, isError, isPending, error } = useMutation({
    mutationKey: ["documents"],
    mutationFn: async (documentId: string) => {
      await deleteDocument({ documentId, token: signedInEntity?.token ?? "" })
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [getPatientDocumentList.name, searchTerms.join(), cat.join()],
      })
      await onAnalytics("document_delete_web", { uid: signedInEntity?.uid })
    },
  })
  return { mutate, data, isPending, isError, error }
}
