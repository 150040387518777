import { apiURL, createDefaultAuthHeaders, createDefaultHeaders } from "."
import { CreateDocumentInputs } from "../components/results/ResultsForm"
import { buildUrlWithSearchParams } from "../utils/api"

export class FileError extends Error {
  // eslint-disable-next-line
  constructor(message: string) {
    super(message)
  }
}

export const getDoctorDocumentList = async ({
  token,
  query,
  cat,
}: {
  token: string
  query: string[]
  cat: string[]
}) => {
  const url = buildUrlWithSearchParams({
    url: `${apiURL}/v2/users/share/documents`,
    values: query,
    categories: cat,
  })
  const response = await fetch(url, { headers: createDefaultHeaders(token) })
  if (!response.ok) {
    throw new Error("Request failed")
  }
  return await response.json()
}

export const getDoctorDocumentDetails = async ({
  documentId,
  token,
}: {
  documentId: string
  token: string
}) => {
  const url = `${apiURL}/users/share/documents/${documentId}`

  const response = await fetch(url, { headers: createDefaultHeaders(token) })
  if (!response.ok) {
    throw new Error("Request failed")
  }
  return await response.json()
}

export const getPatientDocumentDetails = async ({
  documentId,
  token,
}: {
  documentId: string
  token: string
}) => {
  const url = `${apiURL}/users/me/documents/${documentId}`

  const response = await fetch(url, { headers: createDefaultHeaders(token) })
  if (!response.ok) {
    throw new Error("Request failed")
  }
  return await response.json()
}

export const postCreateDocument = async ({
  data,
  token,
}: {
  data: CreateDocumentInputs
  token: string
}) => {
  const url = `${apiURL}/v3/users/me/documents`
  const body = new FormData()
  body.append("file", data.file)
  body.append("title", data.title)
  body.append("category", data.category)
  body.append("samplingDate", data.samplingDate)
  const response = await fetch(url, {
    method: "POST",
    headers: createDefaultAuthHeaders(token),
    body,
  })
  if (!response.ok) {
    if (response.status === 400) {
      const { code } = await response.json()
      throw new FileError(code)
    }
    throw new Error("Request failed")
  }
}

export const getDoctorDocumentPreviewUrl = async ({
  documentId,
  token,
}: {
  documentId: string
  token: string
}) => {
  const url = `${apiURL}/users/share/documents/${documentId}/preview`
  let objectURL = ""

  return fetch(url, { headers: createDefaultHeaders(token) })
    .then(response => response.blob())
    .then(myBlob => {
      objectURL = URL.createObjectURL(myBlob)
      return objectURL
    })
}

export const getPatientDocumentPreviewUrl = async ({
  documentId,
  token,
}: {
  documentId: string
  token: string
}) => {
  const url = `${apiURL}/documents/${documentId}/preview`
  let objectURL = ""

  return fetch(url, { headers: createDefaultHeaders(token) })
    .then(response => response.blob())
    .then(myBlob => {
      objectURL = URL.createObjectURL(myBlob)
      return objectURL
    })
}

export const getPatientDocumentList = async ({
  token,
  query,
  cat,
}: {
  token: string
  query: string[]
  cat: string[]
}) => {
  const url = buildUrlWithSearchParams({
    url: `${apiURL}/v2/users/me/documents`,
    values: query,
    categories: cat,
  })
  const response = await fetch(url, { headers: createDefaultHeaders(token) })
  if (!response.ok) {
    throw new Error("Request failed")
  }
  return await response.json()
}

export const deleteDocument = async ({
  documentId,
  token,
}: {
  documentId: string
  token: string
}) => {
  const url = `${apiURL}/documents/${documentId}`
  const response = await fetch(url, {
    method: "DELETE",
    headers: createDefaultAuthHeaders(token),
  })

  if (!response.ok) {
    if (response.status === 400) {
      const { code } = await response.json()
      throw new FileError(code)
    }
    throw new Error("Request failed")
  }
}
