import React from "react"
import { Link } from "react-router-dom"
import { Box, Button, Card, Typography } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"

export const DocumentsEmptyList: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Button
          sx={{ borderRadius: "20px" }}
          component={Link}
          to="/results/add"
          size="medium"
          startIcon={<AddIcon />}
          variant="contained"
        >
          {t("results.submitButton")}
        </Button>
      </Box>
      <Card sx={{ padding: "20px", marginTop: "10px" }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100px">
          <DescriptionOutlinedIcon sx={{ color: "grey.500" }} />
        </Box>
        <Typography variant="h6" fontWeight="bold" align="left">
          {t("results.nothingHereYet")}
        </Typography>
        <Typography variant="body2" align="left">
          {t("results.addYourFirstDocument")}
        </Typography>
      </Card>
    </>
  )
}
