import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import {
  Modal,
  Box,
  Typography,
  Checkbox,
  Button,
  FormControlLabel,
  Stack,
  CircularProgress,
} from "@mui/material"
import { useTranslation } from "react-i18next"

interface UnauthorizedUserModalProps {
  isPending?: boolean
  isDisabled?: boolean
  open: boolean
  onClose: () => void
  onConfirm: (data: FormValues) => void
}

interface FormValues {
  [key: string]: boolean
}

const checkboxData = [
  {
    name: "all",
    required: false,
  },
  {
    name: "age",
    required: true,
  },
  {
    name: "terms",
    required: true,
    hasLink: true,
    href: "/terms",
  },
  {
    name: "privacy",
    label: "Zapoznałem(-am) się i akceptuję Politykę Prywatności",
    required: true,
    hasLink: true,
    href: "/privacy-policy",
  },
  {
    name: "privacy2",
    required: true,
  },
  {
    name: "optional",
    required: false,
  },
  {
    name: "isMarketingConsentAccepted",
    required: false,
    db: true,
  },
]

export const UnauthorizedUserModal: React.FC<UnauthorizedUserModalProps> = ({
  open,
  onClose,
  onConfirm,
  isPending,
  isDisabled = false,
}) => {
  const { t } = useTranslation()
  const { control } = useForm<FormValues>({
    defaultValues: checkboxData.reduce((acc, checkbox) => {
      acc[checkbox.name] = false
      return acc
    }, {} as FormValues),
    mode: "onChange",
  })
  const [selected, setSelected] = useState<string[]>([])
  const requiredFields = checkboxData.filter(checkbox => checkbox.required)
  const allRequiredAccepted = requiredFields.every(field => selected.includes(field.name))
  const dbFields = checkboxData.filter(checkbox => checkbox.db).map(field => field.name)

  const register = () => {
    if (allRequiredAccepted) {
      const values = selected
        .filter(item => dbFields.includes(item))
        .reduce((acc, field) => ({ ...acc, [field]: true }), {} as Record<string, boolean>)
      dbFields.forEach(field => {
        if (!values[field]) {
          values[field] = false
        }
      })
      onConfirm(values)
    }
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    if (name === "all") {
      setSelected(checked ? checkboxData.map(cb => cb.name) : [])
    } else {
      setSelected(prevSelected =>
        checked ? [...prevSelected, name] : prevSelected.filter(item => item !== name)
      )
    }
  }
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          maxWidth: 600,
          width: "90%",
          maxHeight: "90vh",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 20,
          p: 3,
          borderRadius: 6,
          overflowY: "auto",
        }}
      >
        <Typography variant="h5" align="center">
          {t("register.modal.title")}
        </Typography>
        <form>
          <Stack direction={{ xs: "column" }} spacing={1} alignItems="flex-start">
            {checkboxData.map(checkbox => (
              <Controller
                key={checkbox.name}
                name={checkbox.name}
                control={control}
                rules={{
                  required: checkbox.required,
                }}
                render={({ field }) =>
                  checkbox.hasLink ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          required={checkbox.required}
                          checked={selected.includes(checkbox.name)}
                          onChange={e => {
                            field.onChange(e)
                            handleChange(e)
                          }}
                        />
                      }
                      label={
                        <span>
                          {t(`register.modal.checkbox.${checkbox.name}`)}{" "}
                          <a
                            href={checkbox.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "underline", color: "blue" }}
                          >
                            {t(`register.modal.links.${checkbox.name}`)}
                          </a>
                        </span>
                      }
                    />
                  ) : (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          required={checkbox.required}
                          checked={selected.includes(checkbox.name)}
                          onChange={e => {
                            field.onChange(e)
                            handleChange(e)
                          }}
                        />
                      }
                      label={t(`register.modal.checkbox.${checkbox.name}`)}
                    />
                  )
                }
              />
            ))}
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <Button
                variant="outlined"
                onClick={onClose}
                disabled={isDisabled}
                sx={{ flexGrow: 1, mr: 1, borderRadius: 12 }}
              >
                {t("register.modal.cancel")}
              </Button>
              <Button
                variant="contained"
                onClick={() => register()}
                disabled={!allRequiredAccepted || isDisabled}
                sx={{ flexGrow: 1, ml: 1, borderRadius: 12 }}
              >
                {isPending && <CircularProgress size={24} />}
                {t("register.modal.confirm")}
              </Button>
            </Box>
          </Stack>
        </form>
      </Box>
    </Modal>
  )
}
