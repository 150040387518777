import React, { useEffect } from "react"
import RegisterForm from "../components/register/RegisterForm"
import RegisterCard from "../components/register/RegisterCard"
import { useAuthContext } from "../contexts"
import { useNavigate } from "react-router-dom"

export const Register: React.FC = () => {
  const { signedInEntity } = useAuthContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (signedInEntity) {
      navigate("/results")
    }
  }, [signedInEntity])

  return (
    <>
      <RegisterCard>
        <RegisterForm></RegisterForm>
      </RegisterCard>
    </>
  )
}
