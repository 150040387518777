import { useMutation, useQueryClient } from "@tanstack/react-query"
import { signInPatient } from "../api/auth"
import { FirebaseError } from "firebase/app"
import { onAnalytics } from "../analytics"

interface UseSignInPatientOptions {
  retry?: boolean | number
  retryDelay?: (attempt: number) => number
}

export const useSignInPatient = (options?: UseSignInPatientOptions) => {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, isSuccess, error } = useMutation<
    void,
    FirebaseError,
    { email: string; password: string }
  >({
    mutationKey: [signInPatient.name],
    mutationFn: async ({ email, password }) => {
      await signInPatient(email, password)
      await onAnalytics("login_web", { type: "email" })
      queryClient.removeQueries()
    },
    retry: options?.retry ?? false,
    retryDelay: options?.retryDelay ?? (attemptIndex => Math.min(1000 * 2 ** attemptIndex, 10000)),
  })

  return {
    signIn: mutateAsync,
    isPending,
    isError,
    isSuccess,
    error: error,
  }
}
