import { useMutation } from "@tanstack/react-query"
import { getMe } from "../api/user"
import { onAnalytics } from "../analytics"
import { useAuthContext } from "../contexts"

interface UseSignInPatientOptions {
  retry?: boolean | number
  retryDelay?: (attempt: number) => number
}

export const useGetMe = (options?: UseSignInPatientOptions) => {
  const { signedInNouAuthorizedEntity } = useAuthContext()
  const { mutateAsync, isPending, isError, isSuccess, error } = useMutation<void>({
    mutationKey: [getMe.name],
    mutationFn: async () => {
      if (signedInNouAuthorizedEntity) {
        await getMe({ token: signedInNouAuthorizedEntity.token })
        await onAnalytics("login_web", { type: "email" })
      }
    },
    retry: options?.retry ?? false,
    retryDelay: options?.retryDelay ?? (attemptIndex => Math.min(1000 * 2 ** attemptIndex, 10000)),
  })

  return {
    getMe: mutateAsync,
    isPending,
    isError,
    isSuccess,
    error: error,
  }
}
