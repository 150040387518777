export const apiURL = process.env.REACT_APP_API_URL
export const lambdaApiURL = process.env.REACT_APP_LAMBDA_API_URL

export const createDefaultHeaders = (idToken: string) =>
  new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${idToken}`,
  })

export const createDefaultLambdaHeaders = (idToken: string) =>
  new Headers({
    "Content-Type": "application/json",
    Authorization: `${idToken}`,
  })

export const createDefaultAuthHeaders = (idToken: string) =>
  new Headers({
    Authorization: `Bearer ${idToken}`,
  })

export const createApiKeyHeaders = () =>
  new Headers({
    "Content-Type": "application/json",
    Authorization: `ApiKey ${process.env.REACT_APP_ADALO_API_KEY}`,
  })
