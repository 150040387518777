import { useAuthContext } from "../contexts"
import { useQuery } from "@tanstack/react-query"
import { getDoctorDocumentDetails, getPatientDocumentDetails } from "../api/document"
import { onAnalytics } from "../analytics"

export const useGetDocumentDetails = (documentId: string) => {
  const { signedInEntity } = useAuthContext()
  const { data, isLoading, isError } = useQuery({
    queryKey: [getDoctorDocumentDetails.name],
    queryFn: async () => {
      const handler =
        signedInEntity?.type === "doctor" ? getDoctorDocumentDetails : getPatientDocumentDetails
      await handler({ documentId, token: signedInEntity?.token ?? "" })
      await onAnalytics("document_view_web", {
        user: signedInEntity?.type,
        documentId,
        page_path: "/document-details",
      })
    },
  })

  return { data, isLoading, isError }
}
