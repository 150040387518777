import { apiURL, createApiKeyHeaders } from "."
import {
  signInWithEmailAndPassword,
  FacebookAuthProvider,
  // signInWithPopup,
  // signInWithRedirect,
  createUserWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth"
import { firebaseAuth } from "../firebaseAuth"

export class CodeExpiredError extends Error {
  // eslint-disable-next-line
  constructor(message: string) {
    super(message)
  }
}
export class CodeNotFoundError extends Error {
  // eslint-disable-next-line
  constructor(message: string) {
    super(message)
  }
}
export class UnauthorizedFirebase extends Error {
  // eslint-disable-next-line
  constructor(message: string) {
    super(message)
  }
}

export const getShareCodeStatus = async (codeId: string) => {
  const url = `${apiURL}/users/me/share/${codeId}`
  return fetch(url, { headers: createApiKeyHeaders() })
}

export const authorizeWithShareCode = async (code: string) => {
  const url = `${apiURL}/users/share/auth`
  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ code }),
  })

  if (!response.ok) {
    if (response.status === 401) {
      throw new CodeExpiredError("Code expired")
    }
    if (response.status === 404) {
      throw new CodeNotFoundError("Code not found")
    }
    throw new Error("Request failed")
  }

  return await response.json()
}

export const signInPatient = async (email: string, password: string) => {
  await signInWithEmailAndPassword(firebaseAuth, email, password)
}

export const signInPatientWithFacebook = async () => {
  const provider = new FacebookAuthProvider()
  // await signInWithRedirect(firebaseAuth, provider)
  await signInWithPopup(firebaseAuth, provider)
}

export const createEmailUser = async (email: string, password: string) => {
  await createUserWithEmailAndPassword(firebaseAuth, email, password)
}
