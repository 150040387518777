import { useEffect, useReducer, useState } from "react"
import { Box, Container, Typography } from "@mui/material"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import {
  DocumentCategoryFilter,
  drawerWidth,
  drawerWidthShrink,
} from "../components/DocumentCategoryFilter"
import { DocumentsContext } from "../context"
import { useAuthContext } from "../contexts"
import { Loader } from "../components/Loader"
import { useGetDocuments } from "../hooks/useGetDocuments"
import { useTranslation } from "react-i18next"
import { DocumentListItem } from "../components/documents-list/DocumentList"
import { DocumentIsProcessing } from "../types/document"

const REFRESH_INTERVAL = 10000
const REFRESH_ATTEMPTS = 5

export const Results = () => {
  const { documentId } = useParams()
  const { documents, isError, isLoading, refetch } = useGetDocuments()
  const navigate = useNavigate()
  const [, dispatch] = useReducer(documentsReducer, [])
  const { signedInEntity } = useAuthContext()
  const { t } = useTranslation()
  const [attempts, setAttempts] = useState(0)

  useEffect(() => {
    if (documents) {
      dispatch({ type: "SET_DOCUMENTS", payload: documents })
    }
  }, [documents])

  useEffect(() => {
    if (!signedInEntity) {
      navigate("/login/patient")
    }
  }, [signedInEntity])

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    if (
      documents &&
      documents.some((doc: DocumentListItem) => DocumentIsProcessing.includes(doc.status))
    ) {
      interval = setInterval(() => {
        if (attempts < REFRESH_ATTEMPTS) {
          refetch()
          setAttempts(prev => prev + 1)
        } else {
          if (interval) {
            clearInterval(interval)
          }
        }
      }, REFRESH_INTERVAL)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [documents, refetch, attempts])
  const showFilters = documentId === undefined

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <Typography>{t("errorLoading")}</Typography>
  }

  if (!signedInEntity) {
    return null
  }

  return (
    <DocumentsContext.Provider value={documents}>
      <>
        {showFilters && <DocumentCategoryFilter />}
        <Container
          maxWidth={"xl"}
          component="main"
          sx={{
            height: "100%",
            display: "flex",
            flex: 1,
            justifyContent: "center",
            marginBottom: 12,
            flexGrow: 1,
            marginLeft: showFilters
              ? {
                  md: `${drawerWidth}px`,
                  xs: `${drawerWidthShrink}px`,
                  xxs: "auto",
                }
              : "auto",
            width: showFilters
              ? {
                  md: `calc(100% - ${drawerWidth}px)`,
                  xs: `calc(100% - ${drawerWidthShrink}px)`,
                  xxs: "100%",
                }
              : "100%",
          }}
        >
          <Box mt={4} width="100%">
            <Outlet />
          </Box>
        </Container>
      </>
    </DocumentsContext.Provider>
  )
}

function documentsReducer(documents: DocumentListItem[], action: { type: any; payload: any }) {
  switch (action.type) {
    case "SET_DOCUMENTS":
      return [documents, ...action.payload]
    default: {
      throw Error("Unknown action: " + action.type)
    }
  }
}
